/* SignOutForm.css */

.form-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.form-row-input {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin: 5px;
}

.form-row > * {
  flex: 0 0 auto;
  margin-right: 10px;
}

.form input[type="text"],
.form select {
  font-size: 16px;
  width: 200px;
  height: 30px;
  padding: 5px;
  background-color: #fff; /* White background */
  color: var(--neutral-main-600, #74768f); /* Grayish-black text */
  border: 1px solid #cccccc;
  margin: 5px;
}

.form button {
  width: 100%;
  padding: 10px;
  background-color: var(
    --Darker-Blue-Gray,
    #59738d
  ); /* Dark grayish-blue button background */
  color: var(--neutral-main-50, #fbfbfb); /* Light grayish button text */
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease,
    box-shadow 0.2s ease;
}

.form button:hover {
  background-color: rgba(89, 115, 141, 0.8); /* Darker grayish-blue on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form button:active {
  background-color: #3f5165; /* Darker blue on active */
  transform: translateY(1px);
}

.form button[type="submit"] {
  display: block;
  margin-top: 10px;
}

.error-alert {
  margin-top: 10px;
  padding: 10px;
  background-color: #fff; /* White background for error alert */
  color: var(
    --Darker-Blue-Gray,
    #59738d
  ); /* Dark grayish-blue text for error alert */
  border: 1px solid #cccccc;
  text-align: center;
  font-size: 14px;
}
