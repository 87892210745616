.middle-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15%;
  position: relative;
}

.middle-content::before {
  content: "";
  position: absolute;
  top: 0; /* Position at the top */
  left: 0;
  width: 100%; /* Cover the entire width */
  height: 30%; /* Cover half of the height */
  background-color: #f2f2f2; /* Half red */
  z-index: 0; /* Adjust z-index */
}

.image-container,
.text-container {
  width: 50%;
  position: relative; /* Ensure proper stacking order */
  z-index: 1; /* Ensure text container is above the red background */
}

.t-image {
  width: 150px; /* Adjust image width */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px; /* Adjust spacing between images */
  padding: 10px;
}

.text-container {
  text-align: center;
  padding: 5%;
  margin-top: 50px;
}

.text-container h2 {
  font-size: 24px;
}

.text-container p {
  color: rgba(21, 20, 57, 0.4);
  font: 16px/32px DM Sans, sans-serif;
}

.left-aligned {
  float: left;
}
