.about-container {
  padding: 40px;
  max-width: 900px;
  margin: auto;
  font-family: "Arial", sans-serif;
  color: #333;
}

.about-container h1 {
  text-align: center;
  color: #59738d;
  font-size: 36px;
  margin-bottom: 20px;
}

.about-intro {
  font-size: 18px;
  text-align: center;
  margin-bottom: 40px;
  line-height: 1.6;
  color: #444;
}

.feature-section {
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 8px;
}

.feature-section:nth-of-type(2) {
  background-color: #59738d; /* Blue-gray background */
  color: #ffffff; /* White text for readability */
  width: 100vw; /* Ensure full viewport width */
  margin-left: calc(-50vw + 50%); /* Center the section */
  padding: 40px 200px;
  text-align: center;
}

.feature-section:nth-of-type(2) h2 {
  color: #ffecb3; /* Pale yellow for headings */
}

.feature-section:nth-of-type(2) p {
  color: #dcdcdc; /* Light gray for paragraph text */
}

.feature-content {
  display: flex;
  align-items: center;
  gap: 20px;
}

.feature-text {
  flex: 1;
}

.feature-text h2 {
  font-size: 28px;
  color: #1abc9c;
  margin-bottom: 10px;
}

.feature-text p {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
}

.feature-img {
  max-height: 250px; /* Restrict the height */
  max-width: 100%; /* Ensure the width adjusts proportionally */
  height: auto; /* Maintain the aspect ratio */
  border-radius: 8px; /* Optional: rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow */
}

.feature-image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-left .feature-content {
  flex-direction: row; /* Image on the left */
}

.image-right .feature-content {
  flex-direction: row-reverse; /* Image on the right */
}

@media (max-width: 768px) {
  /* Existing media query for tablets */
  .feature-section:nth-of-type(2),
  .feature-section:nth-of-type(1),
  .feature-section:nth-of-type(3) {
    width: auto;
    margin-left: 0;
    padding: 20px;
  }
}

@media (max-width: 480px) {
  /* For smaller phones */
  .feature-section:nth-of-type(2) h2,
  .feature-section:nth-of-type(2) p {
    font-size: 18px;
  }
  .feature-img {
    max-height: 200px;
  }
}
@media (max-width: 768px) {
  .feature-content {
    flex-direction: column;
  }

  .feature-image {
    margin-top: 20px;
  }
}

/* Stylish footer wrapping up the page */
.about-footer {
  margin-top: 40px;
  padding: 20px;
  text-align: center;
  background-color: #2c3e50; /* Dark background */
  color: #ecf0f1; /* Light text color */
  font-size: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.feature-section:nth-of-type(1),
.feature-section:nth-of-type(3) {
  background-color: #f4f6f8; /* Subtle light gray background */
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  padding: 40px 200px;
  text-align: center;
}
