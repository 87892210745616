/* Left-side panel styling */
.left-side-panel {
  width: 250px;
  background-color: #2c3e50;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  height: auto; /* Let the height grow dynamically */
  min-height: 100vh; /* At least the full viewport height */
}

.panel-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #ecf0f1;
}

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-list li {
  margin-bottom: 15px;
}

.nav-link {
  display: block;
  text-decoration: none;
  color: white;
  font-size: 1rem;
  padding: 10px 15px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.nav-link:hover {
  background-color: #34495e;
  color: #ecf0f1;
}

.active-link {
  background-color: #1abc9c;
  color: white;
  font-weight: bold;
}
