/* Student Navbar Styling */
.student-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  display: flex;
  align-items: center; /* Vertically center */
  justify-content: space-between; /* Distribute space */
  padding: 0 25px;
  z-index: 1000;
}

.student-navbar-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between; /* Key for positioning */
}

.student-logo {
  flex-grow: 0; /* Prevent logo from stretching */
}

.student-logo-img {
  height: 50px;
  object-fit: contain;
}

.navbar-buttons {
  display: flex;
  gap: 10px;
  margin-left: 20px; /* Space from the left edge */
}

.student-logout-button {
  background-color: #e63946; /* Base color */
  color: white;
  border: none;
  padding: 8px 15px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}

.student-logout-button:hover {
  background-color: #c5303c; /* Darker shade of red for hover */
}

/* Modal Styles (unchanged) */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 110%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001; /* Ensure modal is on top */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  overflow: hidden; /* Hides both horizontal and vertical scrollbars */
}

.modal-title {
  margin-bottom: 10px;
}

.modal-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
}

.modal-close-button,
.modal-join-button {
  padding: 10px 15px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-close-button {
  background-color: #ccc;
}

.modal-join-button {
  background-color: #59738d;
  color: white;
}
