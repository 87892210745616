
.settings-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 1.5rem;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.settings-container h2 {
    text-align: center;
    color: #333;
    margin-bottom: 1.5rem;
}

.settings-field {
    margin-bottom: 1rem;
}

.settings-field label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #555;
}

.settings-field input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.settings-feedback {
    margin-top: 1rem;
    padding: 0.5rem;
    color: #fff;
    background-color: #4caf50;
    text-align: center;
    border-radius: 5px;
}
