/* DisplaySignOut.css */

.display-sign-out-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.display-sign-out-table {
  width: 75%;
  text-align: center;
}

.display-sign-out-table th {
  background-color: #e6f3ff;
  padding: 10px;
  border: 1px solid rgb(255, 255, 255); /* Add border style for the menu section (for testing) */
}

.display-sign-out-table td {
  padding: 5px; /* Adjust padding as needed */
  vertical-align: middle;
  border: 1px solid rgb(255, 255, 255);
  background-color: #e6f3ff;
  height: 40px; /* Adjust height as needed */
}

.display-sign-out-table tr {
  border-radius: 10px;
}

.display-sign-out-title {
  margin-bottom: 20px;
}

.display-sign-out-side-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.display-sign-out-side-form {
  width: 45%;
}

.search-container {
  width: 400px;
}

.tab-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-container button {
  padding: 10px 15px;
  border: none;
  background-color: #ccc;
  color: #000;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tab-container button.active-tab {
  background-color: #8884d8;
  color: #fff;
}

.tab-container button:hover {
  background-color: #aaa;
}

/* Container for both date pickers */
.date-picker-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Space between the two date pickers */
  margin-bottom: 20px; /* Space below the container */
}

/* Individual date picker styling */
.date-picker-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align labels and inputs to the left */
  gap: 5px; /* Space between label and date picker */
}

.date-picker-item label {
  font-size: 14px; /* Adjust label font size */
  font-weight: bold; /* Make labels stand out */
  color: #333; /* Darker text color for contrast */
}

.react-datepicker-wrapper {
  width: 100%; /* Ensure date picker spans full width */
}

.react-datepicker__input-container input {
  width: 200px; /* Set a fixed width for consistency */
  padding: 8px 10px; /* Add padding for better input sizing */
  border: 1px solid #ccc; /* Subtle border around the input */
  border-radius: 5px; /* Smooth rounded corners */
  font-size: 14px; /* Adjust font size */
}

.react-datepicker__input-container input:focus {
  outline: none; /* Remove default outline */
  border-color: #8884d8; /* Add a focus color matching the active tab */
  box-shadow: 0 0 4px rgba(136, 132, 216, 0.5); /* Subtle shadow for focus */
}
