:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --font-family: "Arial", sans-serif;
  --font-size-base: 16px;
  --line-height-base: 1.5;
  --neutral-main-50: #fbfbfb;
  --neutral-main-400: #a7a9be;
  --neutral-main-600: #74768f;
  --darker-blue-gray: #59738d;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  color: #333;
  background-color: #f9f9f9;
  overflow: auto;
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

ul,
ol {
  list-style: none;
}
