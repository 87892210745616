.display-classes-container {
  padding: 20px;
}

.class-list {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center class boxes horizontally */
  gap: 20px; /* Add space between class items */
  margin-top: 20px;
  margin-bottom: 20px;
}

.class-item {
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 15px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%; /* Restrict the width of the class boxes */
  max-width: 700px; /* Optional: Limit max width for better scaling */
  text-align: center; /* Center-align text inside the box */
}

.class-info h3 {
  margin-bottom: 10px;
  color: #333;
}

.class-info p {
  margin: 5px 0;
  color: #666;
}

.class-actions {
  display: flex;
  justify-content: center; /* Center the group of buttons */
  align-items: center; /* Vertically align buttons */
  gap: 10px; /* Add spacing between buttons */
  flex-wrap: wrap; /* Allow buttons to wrap to a new row if needed */
  margin-top: 15px;
}

.class-actions button {
  margin: 0; /* Remove any inherited margins */
  flex: 0 0 auto; /* Prevent shrinking and growing */
}
