input,
select,
textarea {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 5px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #f4f6f8;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
}
button,
.red-button {
  margin: 10px;
}

button:last-child,
.red-button:last-child {
  margin-right: 0; /* Remove extra spacing for the last button in a container */
}

.modal-buttons {
  display: flex;
  justify-content: center; /* Center align buttons */
  align-items: center; /* Vertically align buttons */
  gap: 10px; /* Space between buttons */
  margin-top: 20px; /* Space from content above */
  margin: auto;
}

.modal-buttons button {
  width: 150px; /* Consistent button width */
  height: 60px; /* Consistent button height */
}

.modal-buttons button:active {
  background-color: #465d73; /* Darker shade for active state */
  transform: translateY(2px); /* Click effect */
}

/* Updated Button Styles */
button {
  background-color: #59738d; /* Base color */
  color: #fff; /* White text */
  padding: 10px 20px;
  border: 1px solid #59738d;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.1s;
}

button:hover {
  background-color: #4f687e; /* Slightly darker for hover */
}

button:active {
  background-color: #465d73; /* Further darken for active */
  transform: scale(0.95);
}

/* Red Button Style - Retains Distinction */
.red-button {
  background-color: #e63946; /* Base color */
  color: #fff;
  padding: 10px 20px;
  border: 1px solid #e63946;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.1s;
  max-width: 150px;
}

.red-button:hover {
  background-color: #c5303c; /* Darker shade of red for hover */
  transform: scale(1.05); /* Slight enlargement */
  color: #fff; /* Ensure text color remains white */
}

.red-button:active {
  background-color: #a81d28; /* Even darker shade for active */
  transform: translateY(2px); /* Slight push effect */
}

.red-button:last-child {
  margin-right: 0; /* Prevent extra spacing on the last button */
}

.forgot-password-button {
  background: none;
  border: none;
  color: #59738d;
  cursor: pointer;
}

.forgot-password-button:hover {
  background: none;
  border: none;
  color: #59738d;
  cursor: pointer;
}

.google-signin-button {
  background-color: #4285f4;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.google-signin-button:hover {
  background-color: #357ae8;
}

.form-container {
  max-width: 500px;
  margin: auto;
  padding: 20px;
  border: 1px solid var(--neutral-main-600, #74768f);
  border-radius: 5px;
  background-color: var(--neutral-main-50, #fbfbfb);
}

.registration-form {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 0 auto;
  padding: 20px;
}

.form-row {
  margin-bottom: 10px;
  align-items: center;
}

label {
  font-size: 16px;
  font-weight: bold;
  color: var(--neutral-main-600, #74768f);
}

input[type="email"],
input[type="password"],
input[type="text"],
select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid var(--neutral-main-600, #74768f);
  border-radius: 5px;
  text-align: center;
  margin: 5px;
}

input::placeholder {
  color: var(--neutral-main-400, #a7a9be);
}

button[type="submit"] {
  padding: 10px 20px;
  background-color: var(--Darker-Blue-Gray, #59738d);
  color: var(--neutral-main-50, #fbfbfb);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

textarea {
  width: 90%;
  height: 100px;
  margin-bottom: 20px;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}

.form-row:last-child {
  margin-bottom: 0;
}

.registration-form .form-row {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.google-login-button img {
  width: 200px;
  height: auto;
  cursor: pointer;
}

.google-login-button {
  padding: 0;
}
