.add-student-form-container {
  width: 1000px; /* Adjust the width as needed */
  margin: 0 auto;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #7c7c7c;
  border-radius: 8px; /* Slightly more rounded corners for modern look */
  background-color: #f4f6f8; /* Soft light gray for a subtle background */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
}

.table-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table {
  width: 80%;
  text-align: center;
  border-collapse: collapse;
  background-color: #ffffff; /* White background for contrast */
  border: 1px solid #d1d5db; /* Soft gray border */
  border-radius: 10px;
  overflow: hidden; /* Clean edges */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.table th,
.table-admin th {
  background-color: #59738d; /* Primary blue-gray */
  color: #ffffff; /* White text for contrast */
  padding: 12px;
  font-size: 14px;
  text-transform: uppercase; /* Add modern touch */
}

.table td,
.table-admin td {
  padding: 12px;
  color: #333333;
}

.table tr:nth-child(even),
.table-admin tr:nth-child(even) {
  background-color: #f8fafc; /* Very light gray for alternating rows */
  vertical-align: middle; /* Vertically center content */
}

.table tr:nth-child(odd),
.table-admin tr:nth-child(odd) {
  background-color: #e4eaf1; /* Very light gray for alternating rows */
  vertical-align: middle; /* Vertically center content */
}

.table tr:hover,
.table-admin tr:hover {
  background-color: #aaaaaa; /* Slight hover effect */
}

.table-admin {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  background-color: #ffffff; /* White background for contrast */
  border: 1px solid #d1d5db; /* Soft gray border */
  border-radius: 10px;
  overflow: hidden; /* Clean edges */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.table-container h1 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #59738d; /* Accent color */
  font-weight: bold;
}

.side-by-side-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.side-form {
  width: 45%; /* Adjust the width as needed */
}

.display-enrolled-students-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #7c7c7c;
  border-radius: 8px;
  background-color: #f4f6f8;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.enrolled-students-table {
  width: 80%;
}
