/* CenteredElements.css */

.container {
  position: relative;
  text-align: center;
  width: 100%;
  height: 100vh;
}

.background-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px; /* Adjust as needed */
  max-width: 500px; /* Adjust as needed */
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%; /* Adjust as needed */
}

.home-content h1 {
  color: #59738d !important;
  font-size: 2.5rem;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  line-height: 1.2;
}

.home-content p {
  color: rgba(21.06, 20.34, 56.79, 0.4);
  font-size: 1.2rem;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  line-height: 1.5;
}
