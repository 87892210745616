.attendance-viewer-container {
  padding: 20px;
}

.class-selection {
  margin-bottom: 20px;
}

.calendar-container {
  margin-bottom: 20px;
}

.attendance-table {
  margin-top: 20px;
  width: 100%;
}

.attendance-table table {
  width: 100%;
  border-collapse: collapse;
}

.attendance-table th,
.attendance-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.attendance-table th {
  background-color: #f4f4f4;
}

.attendance-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* General Calendar Styling */
.react-calendar {
  background-color: #f7f8fc; /* Light background */
  border: 1px solid #d1d9e6; /* Subtle border */
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-family: "Arial", sans-serif;
}

/* Navigation Styling */
.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
  background-color: #59738d; /* Primary blue */
  color: #ffffff; /* White text */
  border-radius: 8px 8px 0 0;
}

.react-calendar__navigation button {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.react-calendar__navigation button:hover {
  color: #d1ecff; /* Lighter hover effect */
}

/* Days and Weekdays Styling */
.react-calendar__month-view__weekdays {
  font-weight: bold;
  text-transform: uppercase;
  color: #59738d; /* Primary blue */
}

.react-calendar__month-view__days__day {
  font-size: 14px;
  color: #333333; /* Neutral text color */
  text-align: center;
  padding: 8px;
  cursor: pointer;
}

.react-calendar__tile {
  background: #ffffff;
  border: 1px solid #d1d9e6; /* Subtle border */
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.react-calendar__tile:enabled:hover {
  background-color: #e3f2ff; /* Light blue on hover */
  color: #333333;
}

/* Highlight Today's Date */
.react-calendar__tile--now {
  background: #ffde59; /* Bright yellow for today */
  color: #333333; /* Dark text for readability */
  font-weight: bold;
}

/* Selected Date Styling */
.react-calendar__tile--active {
  background: #59738d; /* Primary blue for selected date */
  color: #ffffff; /* White text for contrast */
  font-weight: bold;
}

/* Disabled Days */
.react-calendar__tile--disabled {
  background: #f0f0f0; /* Greyed out for disabled dates */
  color: #9e9e9e; /* Light grey text */
  cursor: not-allowed;
}

/* Arrows Styling */
.react-calendar__navigation button:disabled {
  color: #9e9e9e; /* Disabled arrow */
}

.react-calendar__navigation button svg {
  width: 20px;
  height: 20px;
}
