.div-3 .div-4 .div-5,
.div-3 .div-4 .div-6,
.div-3 .div-4 .div-7 {
  text-decoration: none;
  color: var(--neutral-main-600, #74768f);
}

.div-9 .div-10,
.div-9 .div-11 {
  cursor: pointer;
  text-decoration: none;
}

.div-3 a:visited {
  color: 74768f; /* Inherit color from parent */
}

.navbar a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit color from parent */
}

.div {
  position: fixed; /* Makes the navbar fixed relative to the viewport */
  top: 0; /* Sticks the navbar to the top of the screen */
  left: 0; /* Aligns the navbar with the left edge of the viewport */
  width: 100%; /* Ensures the navbar spans the full width */
  height: 100px;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  display: flex;
  gap: 0px;
  padding: 0 25px;
  z-index: 1000; /* Ensures the navbar appears above other content */
}

@media (max-width: 991px) {
  .div {
    flex-wrap: wrap;
    padding: 0 20px;
  }
}

.div-2 {
  justify-content: center;
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-basis: 0%;
  padding: 15px 10px;
}

.img {
  aspect-ratio: 5.26;
  object-fit: auto;
  object-position: center;
  width: 268px;
  fill: var(--Darker-Blue-Gray, #59738d);
}

.div-3 {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  font-size: 16px;
  color: var(--neutral-main-600, #74768f);
  font-weight: 500;
  white-space: nowrap;
  line-height: 150%;
  padding: 0 60px;
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit color from parent */
}

@media (max-width: 991px) {
  .div-3 {
    max-width: 100%;
    white-space: initial;
    padding: 0 20px;
  }
}

.div-4 {
  display: flex;
  gap: 0px;
}

@media (max-width: 991px) {
  .div-4 {
    white-space: initial;
  }
}

.div-5 {
  font-family: Inter, sans-serif;
  justify-content: center;
  background-color: #fff;
  flex-grow: 1;
  padding: 28px 31px;
}

@media (max-width: 991px) {
  .div-5 {
    white-space: initial;
    padding: 0 20px;
  }
}

.div-6 {
  font-family: Inter, sans-serif;
  justify-content: center;
  background-color: #fff;
  flex-grow: 1;
  padding: 28px 31px;
}

@media (max-width: 991px) {
  .div-6 {
    white-space: initial;
    padding: 0 20px;
  }
}

.div-7 {
  font-family: Inter, sans-serif;
  justify-content: center;
  background-color: #fff;
  flex-grow: 1;
  color: var(--Blue-Gray, #74768f);
  padding: 28px 31px;
}

@media (max-width: 991px) {
  .div-7 {
    white-space: initial;
    padding: 0 20px;
  }
}

.div-8 {
  justify-content: center;
  background-color: #fff;
  display: flex;
  flex-basis: 0%;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  white-space: nowrap;
  line-height: 150%;
  padding: 12px 38px;
}

.div-8-logged-in {
  justify-content: center;
  align-items: end;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #59738d;
  font-weight: 400;
  white-space: nowrap;
  line-height: 150%;
  min-width: 300px;
  padding: 12px 0 12px 60px;
}

@media (max-width: 991px) {
  .div-8 {
    white-space: initial;
    padding: 0 20px;
  }
}

.div-9 {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

@media (max-width: 991px) {
  .div-9 {
    white-space: initial;
  }
}

.div-10 {
  font-family: Inter, sans-serif;
  border-radius: 8px;
  background-color: var(--Darker-Blue-Gray, #59738d);
  flex-grow: 1;
  justify-content: center;
  color: var(--neutral-main-50, #fbfbfb);
  padding: 16px 24px;
}

@media (max-width: 991px) {
  .div-10 {
    white-space: initial;
    padding: 0 20px;
  }
}

.div-11 {
  font-family: Inter, sans-serif;
  border-radius: 8px;
  border: 1px solid #59738d;
  flex-grow: 1;
  justify-content: flex-end; /* Align to the right */
  align-items: center; /* Center vertically */
  color: #59738d;
  padding: 16px 24px;
  margin-left: auto; /* Pushes it to the right */
}
@media (max-width: 991px) {
  .div-11 {
    white-space: initial;
    padding: 0 20px;
    margin-left: 0; /* Reset margin for mobile */
    margin-top: auto; /* Center vertically */
    justify-content: center; /* Center horizontally */
  }
}

@media (max-width: 768px) {
  .modal-content.emergency,
  .modal-content.attendance {
    max-width: 95%; /* Fit the modal within the screen */
    width: 100%;
  }
}

.div-11:hover {
  background-color: rgba(
    89,
    115,
    141,
    0.1
  ); /* Change background color on hover */
}

.div-menu {
  display: flex;
  justify-content: flex-end; /* Align to the right */
  align-items: center; /* Center vertically */
  padding: 15px 10px; /* Adjust padding as needed */
}
