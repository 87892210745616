.heroSection {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  padding: 20px 10%;
  position: relative; /* Needed for z-index to work */
}

.heroContainer {
  display: flex;
  justify-content: center;
  width: 80%;
  max-width: 1351px;
  padding-top: 50px;
}

.heroContent {
  display: flex;
  gap: 20px;
}

.heroContent > * {
  width: 50%; /* Adjust percentage as needed */
}

.heroText {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 44%;
  margin-left: 0;
  justify-content: left;
}

.heroTextContent {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  font-weight: 500;
  margin: auto 0;
  justify-content: left;
  flex-grow: 1;
}

.heroTitle {
  color: #59738d;
  letter-spacing: -1px;
  font: 700 40px/50px DM Sans, -apple-system, Roboto, Helvetica, sans-serif;
  text-align: left;
  padding: 0px !important;
}

.heroDescription {
  color: rgba(21, 20, 57, 0.4);
  font: 18px/32px DM Sans, sans-serif;
  text-align: left;
}

.heroCta {
  border-radius: 100px;
  background-color: #59738d;
  align-self: start;
  margin-top: 20px;
  color: #fff;
  text-align: center;
  justify-content: center;
  padding: 23px 46px;
  font: 20px/130% DM Sans, sans-serif;
}

.heroImageContainer {
  display: flex;
  flex-direction: column;
  line-height: normal;
  margin-left: 20px;
  flex-grow: 1;
  padding: 0px;
}

.heroImage {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 100%;
}
