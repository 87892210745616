/* NavDropDown.css */

.dropdown-toggle::after {
  content: none;
}

.btn-primary,
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active,
.btn:hover {
  color: var(--neutral-main-600); /* Updated to match color scheme */
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: #74768f;
}

.dropdown-item {
  font-family: Inter, sans-serif; /* Updated font family */
  color: #74768f;
}

.dropdown-menu .dropdown-item.active,
.dropdown-menu .dropdown-item:active {
  background-color: #59738d; /* Change background color when active */
  color: #ffffff;
}
