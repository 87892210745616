/* Modal container styling */
.custom-modal {
  display: flex;
  align-items: center;
  text-align: center; /* Centers all inline or inline-block elements within */

  justify-content: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure modal overlays everything */
}

.modal-content {
  background-color: #f9f9f9; /* Modal background matches attendance styles */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 80%; /* Responsive width */
  max-width: 900px; /* Limit maximum width */
  max-height: 80%; /* Limit height to avoid overflow */
  overflow-y: auto; /* Allow scrolling if content overflows */
  display: flex;
  flex-direction: column; /* Use column layout for content */
  text-align: center; /* Centers all inline or inline-block elements within */
}

.modal-content-2 {
  background-color: #f9f9f9; /* Modal background matches attendance styles */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: 80%; /* Responsive width */
  max-width: 900px; /* Limit maximum width */
  max-height: 80%; /* Limit height to avoid overflow */
  overflow-y: auto; /* Allow scrolling if content overflows */
  display: flex;
  flex-direction: column; /* Use column layout for content */
  text-align: center; /* Centers all inline or inline-block elements within */
  margin-bottom: 20px;
}

/* Modal header */
.modal-content h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: bold;
}

/* Modal grid for student items */
.modal-grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(250px, 1fr)
  ); /* Uniform box size */
  gap: 15px; /* Spacing between items */
  justify-items: center;
  width: 100%; /* Ensure grid takes full modal width */
  margin-bottom: 20px;
}

/* Uniform student item styles */
.modal-student-item {
  border: 1px solid #59738d;
  border-radius: 8px;
  background-color: #f9f9f9;
  min-width: 250px;
  min-height: 75px; /* Uniform size for all items */
  max-width: 250px;
  max-height: 75px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.3s ease;
}

/* Hover effect for student items */
.modal-student-item:hover {
  background-color: #59738d;
  color: #fff;
  transform: scale(1.05);
}

/* Scrollbar styles for modal content */
.modal-content::-webkit-scrollbar {
  width: 10px;
}

.modal-content::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.modal-content::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

.cancel-button {
  display: block;
  margin: 20px auto; /* Center horizontally */
  padding: 10px 20px;
  background-color: #e63946;
  color: #fff;
  border: 1px solid #e63946;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.modal-content .cancel-button {
  display: block;
  margin: 20px auto; /* Center horizontally */
  padding: 10px 20px;
  background-color: #e63946;
  color: #fff;
  border: 1px solid #e63946;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.modal-content-2 .cancel-button {
  display: block;
  margin: 20px auto; /* Center horizontally */
  padding: 10px 20px;
  background-color: #e63946;
  color: #fff;
  border: 1px solid #e63946;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.cancel-button:hover {
  background-color: #c5303c;
}

.cancel-button:active {
  transform: scale(0.95);
}
