/* Updated CSS Styles */
/* Styles for the main container */
.attendance-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  margin-bottom: 200px;
}

/* Styles for the class select dropdown */
.class-select {
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 250px;
  text-align: center;
}

/* Styles for the student list grid */
.student-grid {
  margin-top: 20px;
  display: grid;
  width: 80%;
  grid-template-columns: repeat(
    auto-fill,
    minmax(250px, 1fr)
  ); /* Grid layout */
  gap: 15px; /* Space between grid items */
  justify-items: center;
}

/* Styles for each student item in the grid */
.student-item,
.student-item-present {
  border: 1px solid #59738d;
  border-radius: 8px;
  background-color: #f9f9f9;
  min-width: 220px;
  min-height: 75px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.3s ease;
}

/* Hover effect for student items */
.student-item:hover {
  background-color: #59738d;
  color: #fff;
  transform: scale(1.05);
}

/* Click effect for student items */
.student-item:active {
  transform: translateY(2px);
}

/* Styles for present students */
.student-item-present {
  background-color: #59738d;
  color: #fff;
}

/* Timestamp container for checked-in students */
.timestamp-container {
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
}

/* Styles for the collapsible heading */
.student-heading h3 {
  width: 100%;
  text-align: center;
  cursor: pointer;
  margin: 0;
}

/* Fixed button at the bottom of the page */
.fixed-bottom-button {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.fixed-bottom-button button {
  padding: 15px 30px;
  font-size: 16px;
  border-radius: 8px;
}
