/* App.css */

.app-container {
  text-align: center;
  margin-top: 100px; /* Adjust based on the height of your navbar */
  padding-top: 10px;
  background-color: #ffffff;
  min-height: 100vh; /* Fill the entire viewport height */
}

.app-container h1 {
  color: #74768f;
  font-family: Inter, sans-serif;

  padding: 10px;
  margin-bottom: 20px;
}

.app-container h2 {
  font-family: Inter, sans-serif;
  color: #59738d;
  margin-bottom: 10px;
}

.app-content-container {
  margin-top: 20px; /* Adjust the value as needed */
}

.form-row input {
  width: 100%; /* Make sure the input takes the full width of its container */
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  max-width: 300px;
}

/* App.css */

.authenticated-layout {
  display: flex;
  flex-direction: row; /* Align the sidebar and content side-by-side */
  min-height: 100vh; /* Full viewport height */
}

.left-side-panel {
  width: 250px; /* Fixed width */
  background-color: #2c3e50;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  flex-shrink: 0; /* Prevent the sidebar from shrinking */
}

.authenticated-content {
  flex-grow: 1; /* Allow content to take remaining space */
  padding: 20px;
  margin-right: 250px; /* Offset by the sidebar width */
}

.dashboard-content {
  max-width: 1200px; /* Optional: Control content width */
  margin: auto; /* Center content horizontally */
}

/* Styles for the class select dropdown */
.class-select {
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 250px;
  text-align: center;
  max-width: 300px;
}

.about-container .feature-section:nth-child(2) {
  background-color: #f4f6f8; /* Light gray */
}
