/* Updated CSS Styles */
/* Styles for the main container */
.emergency-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-bottom: 200px;
}

/* Styles for the class select dropdown */
.class-select {
  padding: 8px !important;
  font-size: 16px !important;
  margin-bottom: 20px !important;
}

/* Styles for the student heading */
.student-heading {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}

/* Styles for the student list grid */
.student-grid {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  display: grid;
  width: 80%;
  grid-template-columns: repeat(
    auto-fill,
    minmax(250px, 1fr)
  ); /* Adjust column width as needed */
  gap: 10px; /* Adjust gap between grid items */
  justify-items: center;
}

/* Styles for each student item in the grid */
.student-item {
  border: 1px solid var(--Darker-Blue-Gray, #59738d);
  border-radius: 5px;
  background-color: #f0f0f0;
  min-width: 220px;
  min-height: 75px; /* Adjust min-height as needed */
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Added transform for a click effect */
  cursor: pointer; /* Add pointer cursor to indicate interactivity */
}

/* Styles for the student name */
.student-name {
  font-weight: bold;
  align-self: center;
  align-items: center;
}

/* Add a hover effect */
.student-item:hover {
  background-color: var(
    --Darker-Blue-Gray,
    #59738d
  ); /* Lighter color on hover */
  transform: scale(1.05); /* Slight scale-up effect on hover */
}

/* Add a click effect */
.student-item:active {
  background-color: var(--Blue-Gray); /* Darker color on click */
  transform: translateY(2px); /* Slight vertical push effect on click */
}

/* Additional styles for table layout */
.table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.table-header {
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.table-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 8px;
  border: 1px solid var(--neutral-main-600, #74768f);
}

.table-column {
  flex: 1;
  text-align: center;
  padding: 8px;
  border: 1px solid var(--neutral-main-600, #74768f);
  width: 250px;
}

/* Styles for the "Accounted For" student item */
.student-item-accounted {
  border: 1px solid #4caf50; /* Green color scheme */
  border-radius: 5px;
  background-color: #e8f5e9; /* Light green background */
  min-width: 220px;
  min-height: 100px; /* Adjust min-height as needed */
  padding: 10px;
  display: flex;
  flex-direction: column; /* Display content in a column */
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
}

.student-item-accounted span {
  margin-bottom: 0px; /* Adjust the spacing between each line */
  text-align: center;
  display: block; /* Make each span a block element */
}

.clicked-by {
  font-size: 12px; /* Adjust the font size as needed */
  color: #888; /* Adjust the color as needed */
  margin-top: 5px; /* Add margin to separate it from the student name */
}

.grade {
  font-size: 12px; /* Adjust the font size as needed */
  color: #888; /* Adjust the color as needed */
  margin-top: 5px; /* Add margin to separate it from the student name */
}

.student-item-accounted:hover {
  background-color: #4caf50; /* Darker green color on hover */
  transform: scale(1.05); /* Slight scale-up effect on hover */
}

.student-item-accounted:active {
  background-color: #388e3c; /* Darker green color on click */
  transform: translateY(2px); /* Slight vertical push effect on click */
}

/* Additional styles for "Accounted For" additional information */
.additional-info-accounted {
  color: #333; /* Dark text color for additional information */
  margin-top: 5px; /* Adjust margin as needed */
}

/* Styles for the "Not Accounted For" student item */
.student-item-not-accounted {
  border: 1px solid #ff6f6f; /* Reddish border color */
  border-radius: 5px;
  background-color: #f9dada; /* Light red background color */
  min-width: 220px;
  min-height: 75px; /* Adjust min-height as needed */
  padding: 10px;
  display: flex;
  flex-direction: column; /* Display content in a column */
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Added transform for a click effect */
  cursor: pointer; /* Add pointer cursor to indicate interactivity */
}

.student-item-not-accounted:hover {
  background-color: #ff6f6f; /* Darker red color on hover */
  transform: scale(1.05); /* Slight scale-up effect on hover */
}

.student-item-not-accounted:active {
  background-color: #e53935; /* Darker red color on click */
  transform: translateY(2px); /* Slight vertical push effect on click */
}

/* Additional styles for "Not Accounted For" additional information */
.additional-info-not-accounted {
  color: #333; /* Dark text color for additional information */
  margin-top: 0px; /* Adjust margin as needed */
}

.smaller-timestamp {
  font-size: 12px; /* Adjust the font size as needed */
  color: #888; /* Adjust the color as needed */
  margin-top: 2px; /* Add margin to separate it from the student name */
}

.enrolled-classes {
  font-size: 12px; /* Adjust the font size as needed */
  color: #888; /* Adjust the color as needed */
  margin-top: 5px; /* Add margin to separate it from the student name */
}
