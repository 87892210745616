.ContentTop {
  width: 100%;
  height: 100%;
  position: relative;
  background: #f2f2f2;
}

.Text {
  height: 438px;
  left: 286px;
  top: 212px;
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
}

.YourAllInOneSchoolManagementSolution {
  width: 578px;
  color: #59738d;
  font-size: 58px;
  font-family: "DM Sans";
  font-weight: 700;
  line-height: 70px;
  word-wrap: break-word;
}

.StayOrganizedInformedAndPreparedWithClassboon {
  width: 483px;
  color: rgba(21.06, 20.34, 56.79, 0.4);
  font-size: 22px;
  font-family: "DM Sans";
  font-weight: 500;
  line-height: 32px;
  word-wrap: break-word;
}

.BigFill {
  width: 268px;
  height: 60px;
  position: relative;
}

.Rectangle {
  width: 268px;
  height: 60px;
  left: 0;
  top: 0;
  position: absolute;
  background: #59738d;
  border-radius: 100px;
}

.SignUp {
  left: 45px;
  top: 17px;
  position: absolute;
  text-align: center;
  color: white;
  font-size: 20px;
  font-family: "DM Sans";
  font-weight: 500;
  line-height: 26px;
  word-wrap: break-word;
}

.Group1 {
  width: 743px;
  height: 743px;
  left: 864px;
  top: 53px;
  position: absolute;
}

.Ellipse7 {
  width: 743px;
  height: 743px;
  left: 0;
  top: 0;
  position: absolute;
  background: #d9d9d9;
  border-radius: 9999px;
}

.November20120541 {
  width: 1114px;
  height: 745.75px;
  left: -185px;
  top: -3px;
  position: absolute;
}
