/* General styles for the main container */
.main-signout-container {
  max-width: 90%;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 2px solid #8d8d8d;
}

/* Active sign-out container styles */
.active-signout-container {
  background-color: #72c472; /* Vibrant green */
  border: 2px solid #5a9f5a;
  padding: 20px;
  border-radius: 8px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

/* Sign-out form container styles */
.signout-form-container {
  padding: 20px;
  background-color: #f1f1f1;
  border: 2px solid #8d8d8d;
  border-radius: 8px;
}

/* Button styles */
.signout-button,
.sign-in-button {
  background-color: #2b4d6e; /* Deep navy */
  color: #ffffff;
  padding: 10px 20px;
  border: 2px solid #1c567d;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.signout-button:hover,
.sign-in-button:hover {
  background-color: #1f3851;
  border-color: #3d6a8a;
}

/* Button group layout */
.button-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 10px 0;
}

/* Class and location buttons */
.class-button,
.location-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  padding: 8px;
  border: 1px solid #777777;
  border-radius: 10px;
  cursor: pointer;
  background-color: #f0f0f0;
  color: #333;
  transition: background-color 0.3s, color 0.3s, filter 0.3s;
}

.location-button {
  height: 150px;
}

/* Location icon styles */
.location-icon {
  width: 64px;
  height: 64px;
  margin-bottom: 8px;
  object-fit: contain;
  filter: brightness(0) invert(0);
  transition: filter 0.3s;
}

/* Selected and hover styles */
.class-button.selected,
.location-button.selected,
.class-button:hover,
.location-button:hover {
  background-color: #59738d; /* Blue-gray color */
  color: white;
}

.location-button.selected .location-icon,
.location-button:hover .location-icon {
  filter: brightness(0) invert(1); /* Invert icon color */
}

/* Custom location input */
.custom-location-input {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

/* Submit sign-out button */
.signout-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.signout-button:hover {
  background-color: #1e7e34;
}
