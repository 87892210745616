.admin-panel-login-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.admin-panel-login-container {
  width: 40%;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.admin-panel-form-group {
  margin-bottom: 15px;
  text-align: left;
}

.admin-panel-form-group label {
  display: block;
  margin-bottom: 5px;
}

.admin-panel-container {
  padding: 20px;
  width: 90%;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.admin-panel-summary-boxes {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.admin-panel-summary-box {
  flex: 1;
  margin: 0 10px;
  padding: 20px;
  background-color: #f4f6f8;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.admin-panel-table-container {
  overflow-x: auto;
}

.admin-panel-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.admin-panel-table th,
.admin-panel-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.admin-panel-table th {
  background-color: #59738d;
  color: white;
  text-transform: uppercase;
}

.admin-panel-error-message {
  color: red;
  font-size: 12px;
}
